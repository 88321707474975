import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Dialog } from '@capacitor/dialog'
import { createPopper } from '@popperjs/core'

export const mixFormGeral = {
  computed:
  {
    mqShallShowLeftSidebar: {
      get() { return this.$store.getters['listings_form/mqShallShowLeftSidebar'] },
      set(newValue) {
        this.$store.commit('listings_form/setMqShallShowLeftSidebar', newValue)
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (store.state.capacitor.isNative === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast.clear()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async showMsgSuccessRequest(info) {
      if (info.message) {
        await this.$swal({
          title: '',
          html: info.message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
          },
          buttonsStyling: false,
        })
      }
    },
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    withPopperGroup(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'
      oDropdownList.classList.add('v-select-optgroup')

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    renderObjToTxt(value, field) {
      if (Array.isArray(value)) {
        let txt = ''
        value.forEach(o => {
          if ((o !== null) && (o !== undefined) && (typeof o === 'object') && (field in o)) {
            txt += `${o[field]}, `
          }
        })

        return txt.slice(0, -2) || '-----'
      }

      let txt = value
      if ((value !== null) && (value !== undefined) && (typeof value === 'object') && (field in value)) {
        txt = value[field]
      }

      return txt || '-----'
    },
  },
  filters: {
    getTxt(value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          let txt = ''

          value.forEach(o => {
            if ((o !== null) && (o !== undefined)) {
              txt += `${o}, `
            }
          })

          return txt.slice(0, -2) || '-----'
        }
        return '-----'
      }

      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
    truncate(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
    lowerBold(value) {
      if (!value) return ''
      return `<b>${value.toLowerCase()}</b>`
    },
  },
}

export const _ = null
