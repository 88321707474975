import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  form_data: ref({}),
  user: ref({}),
  agentExternal: ref({}),
  listing: ref({}),
  typeBusiness: ref([]),
  listingImages: ref({}),
  visit: ref({}),
  banks: ref({}),
  actionLead: ref({}),
  commission: ref({}),
  proposalsToApproveAll: ref(''),
  proposalsToConcluirAll: ref(''),
  configApp: ref({}),
  cargo: ref(''),
  proposal: ref({}),
  imagePreview: ref(''),
  typeReasons: [
    {
      id: '0',
      desc: i18n.t('O valor'),
    },
    {
      id: '1',
      desc: i18n.t('Outro'),
    },
  ],
})

export default defaultState
