import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    form_data: state => state.form_data.value,
    user: state => state.user.value,
    agentExternal: state => state.agentExternal.value,
    listing: state => state.listing.value,
    typeBusiness: state => state.typeBusiness.value,
    listingImages: state => state.listingImages.value,
    visit: state => state.visit.value,
    banks: state => state.banks.value,
    actionLead: state => state.actionLead.value,
    commission: state => state.commission.value,
    proposalsToApproveAll: state => state.proposalsToApproveAll.value,
    proposalsToConcluirAll: state => state.proposalsToConcluirAll.value,
    configApp: state => state.configApp.value,
    cargo: state => state.cargo.value,
    proposal: state => state.proposal.value,
    typeReasons: state => state.typeReasons,
    imagePreview: state => state.imagePreview.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setUser(state, payload) {
      state.user.value = payload
    },
    setAgentExternal(state, payload) {
      state.agentExternal.value = payload
    },
    setListing(state, payload) {
      state.listing.value = payload
    },
    setListingImages(state, payload) {
      state.listingImages.value = payload
    },
    setTypesBusiness(state, payload) {
      state.typeBusiness.value = payload
    },
    setVisit(state, payload) {
      state.visit.value = payload
    },
    setBanks(state, payload) {
      state.banks.value = payload
    },
    setActionLead(state, payload) {
      state.actionLead.value = payload
    },
    setCommission(state, payload) {
      state.commission.value = payload
    },
    setProposalsToApproveAll(state, payload) {
      state.proposalsToApproveAll.value = payload
    },
    setProposalsToConcluirAll(state, payload) {
      state.proposalsToConcluirAll.value = payload
    },
    setConfigApp(state, payload) {
      state.configApp.value = payload
    },
    setCargo(state, payload) {
      state.cargo.value = payload
    },
    setPreview(state, payload) {
      state.imagePreview.value = payload
    },
    setProposal(state, payload) {
      state.proposal.value = payload
    },
    setUpdateFormData(state, payload) {
      state.form_data.value = { ...payload }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field && (payload.value || payload.value === null)) {
          state.form_data.value[payload.field] = payload.value
        }
      } catch (err) {
        //
      }
    },
    setResetFormData(state, payload) {
      state.form_data.value = payload
    },
  },
  actions: {
    // Obter os filtros do formulário
    async getDataForm({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/propostas/newProposal/${payload}`)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setTypesBusiness', response.data.data.sw007)
              commit('setListing', response.data.data.sw012)
              commit('setListingImages', response.data.data.sw014)
              commit('setVisit', response.data.data.sw070)
              commit('setBanks', response.data.data.sw129)
              commit('setActionLead', response.data.data.actionAccessByLead)
              commit('setCommission', response.data.data.comissao)
              commit('setProposalsToApproveAll', response.data.data.proposalsToApproveAll)
              commit('setProposalsToConcluirAll', response.data.data.proposalsToConcluirAll)
              commit('setConfigApp', response.data.data.configsAPP.app)
              commit('setCargo', response.data.data.cargo)
              commit('setPreview', response.data.data.imagePreviewListing)

              if (typeof response.data.data.sw001 !== 'undefined') {
                commit('setUser', response.data.data.sw001)
              }

              if (typeof response.data.data.sw025 !== 'undefined') {
                commit('setAgentExternal', response.data.data.sw025)
              }

              state.form_data.value.sw131s03 = state.typeBusiness.value.find(o => Number(o.sw007s01) === Number(state.listing.value.sw012v06))

              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao carregar formulário da proposta')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar formulário da proposta')))
            }
          })
      })
    },

    // Obter os filtros do formulário
    async newFlowProposalModal({ state, commit }, payload) {
      const formData = new FormData()

      formData.append('sw130s01', payload.sw130s01)
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/propostas/newFlowProposalModal`, formData)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setCommission', response.data.data.comissao)
              commit('setConfigApp', response.data.data.configsAPP.app)
              commit('setCargo', response.data.data.cargo)

              if (typeof response.data.data.sw012 !== 'undefined') {
                commit('setListing', response.data.data.sw012)
              }

              if (typeof response.data.data.sw007 !== 'undefined') {
                commit('setTypesBusiness', response.data.data.sw007)
              }

              if (typeof response.data.data.sw130 !== 'undefined') {
                commit('setProposal', response.data.data.sw130)
              }

              state.form_data.value.sw131s03 = state.typeBusiness.value.find(o => Number(o.sw007s01) === Number(response.data.data.typeNegocio))
              state.form_data.value.comissao = `${state.commission.value.sw012s16} | ${state.commission.value.sw012s15}%`
              state.form_data.value.motivo = state.typeReasons.find(o => Number(o.id) === 0)

              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao carregar formulário da contra-proposta')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar formulário da contra-proposta')))
            }
          })
      })
    },

    // Save nova proposta
    async saveProposal({ state }, payload) {
      const formData = new FormData()

      formData.append('sw130s02', btoa(state.visit.value.sw070s01))
      formData.append('sw130s03', state.visit.value.sw070s10)
      formData.append('sw130s04', btoa(state.visit.value.sw012v01))
      formData.append('sw130s05', btoa(state.user.value.sw001s01))
      formData.append('sw130s06', btoa(state.visit.value.sw025s01))

      if (payload.reforcos.length > 0) {
        payload.reforcos.forEach(item => {
          if (item.valor !== '' && item.data !== '') {
            formData.append('sw132s03[]', item.valor)
            formData.append('sw132s04[]', item.data)
          }
        })
      }

      if (payload.files.length > 0) {
        payload.files.forEach(item => {
          if (item.name !== '') {
            formData.append('sw131s09[]', item.name)
            formData.append('fileLegenda[]', item.legend)
          }
        })
      }

      Object.entries(state.form_data.value).forEach(row => {
        const [name, value] = row
        if (name !== 'sw131s03') {
          if (name === 'sw130s09') {
            formData.append(name, value.sw129s01)
          } else {
            formData.append(name, value)
          }
        } else {
          formData.append(name, value.sw007s01)
        }
      })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/propostas/save`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(i18n.t('Problema ao submeter proposta')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao submeter proposta')))
            }
          })
      })
    },

    updateFieldValue({ state, commit }, payload) {
      commit('setUpdateFormDataField', payload)
      commit('setUpdateFormData', state.form_data.value)
    },

    resetFormData({ commit }) {
      commit('setResetFormData', {})
    },

  },
}
